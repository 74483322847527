/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* in-flight clone */

.alert-radio-label.sc-ion-alert-md {
    white-space: pre-line !important;
}

.alert-radio-label.sc-ion-alert-ios {
    white-space: pre-line !important;
}

ion-select {
    max-width: 100% !important; // your choice :
}

.scroll-content {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important;
}

ion-grid {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

ion-content {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}


/* In your global CSS file */

.opacity8 {
    opacity: 0.8 !important;
}

:root {
    --trust_blue: #112E51;
    --trust_orange: #D87430;
}

.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}

html {
    scroll-behavior: smooth;
}

.native-input[disabled].sc-ion-input-md {
    opacity: 1 !important;
}


/* high-performance display:none; helper */

.gu-hide {
    left: -9999px !important;
}


/* added to mirrorContainer (default = body) while dragging */

.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

.my-modal-class {
    .modal-wrapper {
        @media screen and (max-width: 799px) {
            height: 30%;
            width: 90%;
        }
        height:40%;
        width: 65%;
        border-radius: 15px;
    }
}

#ForRadioOptions .alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
    white-space: normal;
}

.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}

#ForCheckbox .alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
    white-space: normal;
}

.alert-tappable.alert-checkbox {
    height: auto;
    contain: content;
}


/* added to the source element while its mirror is dragged */

.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.footer {
    flex: 1;
    height: 30vh;
}

.wrapper {
    padding-bottom: 10%;
    // Line 1
    display: flex;
    // Line 2
    flex-direction: column;
}

.footer_div {
    display: block;
    font-size: 14px;
    width: 90%;
    float: right;
    font-size: 16px;
    min-height: 30vh;
    padding-top: 10px;
    padding-left: 100px;
    @media(max-width: 1024px) {
        display: block;
        font-size: 14px;
        width: 80%;
        float: right;
    }
    @media(max-width: 768px) {
        display: grid;
        width: 100%;
        padding-left: 0px;
        margin-top: 0px;
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        height: 20vh;
    }
    @media(max-width: 480px) {
        display: grid;
        width: 100%;
        padding-left: 0px;
        margin-top: 0px;
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        height: 20vh;
    }
}

.press-kiethik {
    @media(min-width: 1025px) {
        position: absolute;
        top: 40%;
        left: 35%;
        width: 100%;
        -ms-transform: translate(-40%, -60%);
        transform: translate(-40%, -60%);
    }
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    -ms-transform: translate(-40%, -60%);
    transform: translate(-40%, -60%);
    @media(min-width: 1500px) {
        top: 30%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    @media(max-width: 1024px) {
        position: absolute;
        top: 25%;
        width: 40%;
        -ms-transform: translate(-40%, 50%);
        transform: translate(-40%, -50%);
    }
    @media(max-width: 768px) {
        display: none;
    }
    @media(max-width: 480px) {
        display: none;
    }
}

ion-content {
    --background: no-repeat 100% 100%;
    --background: var(--ion-background-color, #D87430);
    text-align: center;
    display: flex;
    flex-direction: column;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: auto;
}

ion-grid {
    flex: 1;
    min-height: 100%;
}

.question-card {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.card-div {
    flex: 1;
}

.card-footer {
    flex: 1;
    position: absolute;
    bottom: 0
}

.title-logo {
    width: 150px;
    float: left;
    padding: 10px 10px 5px 20px;
    @media (min-width: 1000px) {
        margin-right: -150px
    }
    @media (max-width: 950px) {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
    }
}

.title_text {
    padding-bottom: 5px;
}